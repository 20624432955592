import { EnvName, IdentityManagement } from './config.model';
import { IConfigMap } from './configMap';

export const prodConfig: IConfigMap = {
  environment: EnvName.PROD,
  name: EnvName.PROD,
  proxyUri: '',
  rootUri: 'https://demo.api.decisionsfirst.com/',
  authBaseUrl: 'https://demo.api.decisionsfirst.com/ims/',
  identityManagement: IdentityManagement.WSO2,
};
